@tailwind base;
@tailwind components;
@tailwind utilities;

@function vw($px) {
  @return $px / 375 * 100vw;
}

html,
body {
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

html {
  height: 100%;
  font-size: vw(10);
  font-family: var(--font-inter), var(--font-noto_sans_jp);
  @media (min-width: 431px) {
    font-size: 10px;
    background-image: url('/images/background.webp');
    @supports not (background-image: url('/images/background.webp')) {
      background-image: url('/images/background.jpg');
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}

body {
  font-size: 1.6rem;
  min-height: 100%;
  font-feature-settings: 'palt' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

:root {
  --header-height: 6.5rem;
  --bottom-nav-height: 6.9rem;
  --channel-footer-no-ticket-height: 13.6rem;
  --channel-footer-not-logged-in-height: 14.75rem;
  --vh-main: calc(100dvh - var(--header-height) - var(--bottom-nav-height));
  --vh-header: calc(100dvh - var(--header-height));
  --vh-bottom-nav: calc(100dvh - var(--bottom-nav-height));
  --vh-sp-guest-menu: calc(100dvh - var(--bottom-nav-height));
  --md-base-width: 375px;
  --lg-base-width: 575px;
  // TODO: Buttonに流し込むために一旦ここに定義。もっとう良い方法を考えて適用する
  --color-blue: #0132bf;
  --break-point-md: 431px;
}

/** ------------------------------
    reset
------------------------------**/
*,
*:before,
*:after {
  box-sizing: inherit;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

img {
  max-width: 100%;
  display: block;
}

button,
textarea {
  font: inherit;
  border: none;
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
}

#__next {
  min-height: 100%;
  overflow-x: hidden;
}

iframe {
  border: none;
}

.disabled {
  pointer-events: none;
}

[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
}

[type='radio'] {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  color: white;
  border: 1px solid #999999 !important;
  box-shadow: none !important;
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
